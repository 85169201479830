<template>
  <div class="i-aside-list">
    <div class="i-aside-list-title" v-if="true">
      <span class="d-block name">
        <!-- <i class="el-icon-tickets imr-1"></i> -->
      {{title}}</span>
    </div>
    <el-table class="i-aside-list-table" :data="datas" style="width: 100%" :show-header="false" @cell-click="rowClick">
      <el-table-column :prop="`${props.name}`">
        <div class="cell-inner" slot-scope="scope" >
          {{scope.row[props.name]}}<span class="cell-inner-sn">{{getName(scope.row)}}</span>
          <div style="flex: 1 1 auto"></div>
          <img class="alarm" :src="att_logo" v-if="getAlarm(scope.row)"/>
          <svg class="icon" aria-hidden="true" v-if="getStatus(scope.row) == 0">
              <use xlink:href="#icon-lixian"></use>
          </svg>
          <svg class="icon" aria-hidden="true" v-else-if="getStatus(scope.row) == 1">
              <use xlink:href="#icon-black-dot-copy"></use>
          </svg>
          <svg class="icon" aria-hidden="true" v-else-if="getStatus(scope.row) == 2">
              <use xlink:href="#icon-orange-dot-copy"></use>
          </svg>
          <svg class="icon" aria-hidden="true" v-else-if="getStatus(scope.row) == 3">
              <use xlink:href="#icon-zaixian"></use>
          </svg>
          <svg class="icon" aria-hidden="true" v-else>
              <use xlink:href="#icon-lixian"></use>
          </svg>
        </div>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    datas: {
      type: Array,
      default: () => ([])
    },
    props: {
      type: Object,
      default: () => ({
        name: 'name',
        state: 'state',
        id: 'id'
      })
    },
    getStatus: {
      type: Function,
      default: () => {
        return true
      }
    },
    getName: {
      type: Function,
      default: () => {
        return true
      }
    },
    getAlarm: {
      type: Function,
      default: () => {
        return false
      }
    },
    delay: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    //'datas': 'doFilter'
  },
  data() {
    return {
      innerValue: []
    }
  },
  methods: {
    rowClick(item) {
      this.$emit('click', item)
    },
    doFilter() {
      if (this.delay) {
        setTimeout(() => {
          this.innerValue = this.datas.filter(this.filterMethod).slice()
        }, 200)
      } else {
         this.innerValue = this.datas.filter(this.filterMethod).slice()
      }
    }
  },
  created() {
    //this.innerValue = this.datas.filter(this.filterMethod).slice()
    this.att_logo = require('../../assets/icon/alarm.gif')
  }
}
</script>

<style lang="scss" scoped>
.i-aside-list {
  position: relative;
  height: 100%;
  overflow: hidden;

  &-title {
    .name {
      border-bottom: 1px solid #DCDFE6;
      font-size: 14px;
      color: #303133;
      background-color: #DCDFE6;
      padding-left: 10px;
      height: 44px;
      line-height: 44px;
    }
  }


  &-table {
    &::before {
      height: 0;
    }
    & ::v-deep td {
      cursor: pointer;
    }
    height: calc(100% - 44px);
    overflow-y: auto;

    .cell-inner {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // color: #009FDB;
      cursor: pointer;
      & ::v-deep .icon {
        font-size: 22px;
      }
      .cell-inner-sn {
        margin-left: 10px;
        display: none;
      }
      & .alarm {
        height: 20px;
        transform: translateY(-2px);
        margin-right: 8px;
      }
      // &:hover .cell-inner-sn {
      //   display: inline-block;
      // }
    }

    & ::v-deep .el-table__row:hover .cell-inner-sn {
      display: inline-block;
      color: #909399;

    }
  }
}
</style>

